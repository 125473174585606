// import axios from "axios"
import axios from "../../constants/axios"
import { navigate } from "gatsby"
import endpoints from "../../constants/endpoints"

export const FETCH_JOBS = async query_params => {
  try {
    const res = await axios.get(
      process.env.GATSBY_BASE_URL + endpoints.JOB_POSTING_BASE + query_params
    )
    if (res.status === 200) return res
  } catch (error) {
    return error
  }
}

export const FETCH_JOB_DETAILS = async slug => {
  try {
    const res = await axios.get(
      process.env.GATSBY_BASE_URL + endpoints.JOB_POSTING_BASE + slug
    )
    if (res?.status === 200) return res?.data
  } catch (error) {
    navigate("/404")
  }
}

export const DELETE_JOB = async slug => {
  try {
    const res = await axios.delete(
      process.env.GATSBY_BASE_URL + endpoints.JOB_POSTING_BASE + slug + "/"
    )
    return res
  } catch (error) {}
}

export const CANDIDATE_APPLY_JOB = async data => {
  try {
    const res = await axios.post(
      process.env.GATSBY_BASE_URL +
        endpoints.JOB_POSTING_BASE +
        endpoints.JOB_APPLY_ROUTE,
      data
    )
    return res
  } catch (error) {
    return error?.response
  }
}

export const CANDIDATE_SAVE_JOB = async data => {
  try {
    const res = await axios.post(
      process.env.GATSBY_BASE_URL +
        endpoints.JOB_POSTING_BASE +
        endpoints.JOB_SAVE_ROUTE,
      data
    )
    return res
  } catch (error) {
    return error?.response
  }
}
