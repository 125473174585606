import React, { useContext, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"

import { GlobalAuthContext } from "../../../context/authContext"
import getCountryData from "../../../utils/get-country-data"

import "./search-button.scss"
import InputBox from "../../input/wdn-input-box"
import { navigate } from "gatsby"

const SearchButton = () => {
  const [searchText, setSerachText] = useState("")
  const [error, setError] = useState("")

  const { state } = useContext(GlobalAuthContext)

  const handleSearchTextChange = e => {
    if (typeof e !== "string") {
      setSerachText(e.target.value)
    } else {
      setSerachText(e)
    }
  }

  const onFocus = () => {
    setError({})
  }

  const handleEnterKeyPress = e => {
    e.preventDefault()
    if (searchText) {
      const country = getCountryData(
        state.config.country,
        state.profile.country
      )
      navigate(
        "/search-candidates?position=" +
          searchText +
          "&industry=" +
          state.profile.industry +
          "&country=" +
          state.profile.country +
          "&minSalary=" +
          country?.minSalary +
          "&minExperience=0&maxExperience=" +
          5
      )
    } else {
      setError({ searchText: "This is required" })
    }
  }
  return (
    <section className="search-button">
      <Container>
        <Row>
          <Col lg="7 m-auto">
            <form onSubmit={handleEnterKeyPress}>
              <Row className="align-items-center">
                <Col xs="9">
                  <div className="search-input">
                    <InputBox
                      styles={error?.searchText ? "error" : "allow-focus"}
                      onFocus={onFocus}
                      type="text"
                      onChange={handleSearchTextChange}
                      value={searchText}
                      placeholder="Position or Skills"
                    />
                  </div>
                </Col>
                <Col xs="3">
                  <div className="search-button">
                    <button
                      // onClick={handleEnterKeyPress}
                      className="btn btn-small w-100 blue-background text-white no-border-radius"
                    >
                      Search
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default SearchButton
