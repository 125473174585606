import React, { useContext, useEffect, useState } from "react"

import { GlobalAuthContext } from "../../context/authContext"

import { COMPANY_DETAIL_FETCH } from "../company-profile/actions/detailActions"
import { CANDIDATE_PERSONAL_DATA_FETCH } from "../candidate-profile/actions/personalAction"

const ProfileDataFetcher = () => {
  const { state, dispatch } = useContext(GlobalAuthContext)
  const [fake, setFake] = useState([])

  useEffect(() => {
    if (
      state?.isAuthenticated &&
      state?.user?.role === 2 &&
      !state?.profile?.avatar
    ) {
      CANDIDATE_PERSONAL_DATA_FETCH(
        state?.user?.id,
        fake,
        setFake,
        setFake,
        dispatch
      )
    } else if (
      state?.isAuthenticated &&
      state?.user?.role === 3 &&
      !state?.profile
    ) {
      COMPANY_DETAIL_FETCH(dispatch, setFake, fake, setFake)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <></>
}

export default ProfileDataFetcher
