import React, { useCallback, useContext, useEffect, useState } from "react"

import { FETCH_JOB_DETAILS } from "../../components/job/job-actions"

import { GlobalAuthContext } from "../../context/authContext"

import CompanyProfileLayout from "../../components/layout/company-profile"
import Spinner from "../../components/spinner/spinner"
import CandidateCard from "../../components/candidate-card/candidate-card"
import { SHORTLIST_FETCH } from "../../components/company-profile/statistics/action/statisticsAction"
import {
  ApplicantsListingMenu,
  ShortlistedApplicantsListingMenu,
} from "../../constants/profile-menu"

const ApplicantsPage = ({ slug, isShortlist }) => {
  const { state, dispatch } = useContext(GlobalAuthContext)

  const [loading, setLoading] = useState(false)

  const fetchJobDetails = async () => {
    if (slug) {
      const res = await FETCH_JOB_DETAILS(slug)
      dispatch({ type: "JOB_APPLICANTS", payload: res })
    } else {
    }
  }

  useEffect(() => {
    fetchJobDetails()
    return () => {
      dispatch({ type: "JOB_APPLICANTS", payload: [] })
    }
  }, [slug]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (state?.user?.company) {
      SHORTLIST_FETCH(dispatch, () => {}, state?.user?.company?.id)
    }
  }, [state?.user]) // eslint-disable-line react-hooks/exhaustive-deps

  const menu = isShortlist
    ? ShortlistedApplicantsListingMenu
    : ApplicantsListingMenu
  const title = isShortlist
    ? "Shortlisted Applicants Listing"
    : "Applicants Listing"

  const getApplicantsList = useCallback(
    state => {
      return isShortlist
        ? state?.job_detail_with_applicants?.shortlisted_applicants
        : state?.job_detail_with_applicants?.job_applicants
    },
    [isShortlist]
  )
  return (
    <CompanyProfileLayout
      title="Applicants List"
      menu={menu}
      sidebarMenu={[]}
      active={title}
    >
      {state?.job_detail_with_applicants ? (
        getApplicantsList(state)?.length > 0 ? (
          <>
            <h1 className="applicants-listing-title mb-4 mt-4">
              {state?.job_detail_with_applicants?.title}
              <span className="blue-text">
                :&nbsp; {state?.job_detail_with_applicants?.job_type}
              </span>
            </h1>
            <>
              {state?.job_detail_with_applicants?.job_applicants?.map(
                applicant => {
                  return (
                    <CandidateCard
                      candidate={{
                        ...applicant,
                        candidate: {
                          id: applicant?.candidate_id,
                          ...applicant,
                        },
                        profile: applicant,
                      }}
                      displayShortlistButton
                      shortlist={state?.shortlist}
                      loading={loading}
                      setLoading={setLoading}
                      isApplicant
                    />
                  )
                }
              )}
            </>
          </>
        ) : (
          <h1>No Applicants Found</h1>
        )
      ) : (
        <Spinner />
      )}
    </CompanyProfileLayout>
  )
}

export default ApplicantsPage
