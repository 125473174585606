export const CANDIDATE_STEP_MENU = [
  {
    link: "/candidate/personal",
    name: "Personal",
  },
  {
    link: "/candidate/professional",
    name: "Professional",
  },
  {
    link: "/candidate/education",
    name: "Education",
  },
]

export const COMPANY_STEP_MENU = [
  {
    link: "/company/company-detail",
    name: "Company Detail",
  },
]

export const ApplicantsListingMenu = [
  {
    link: "",
    name: "Applicants Listing",
  },
]
export const ShortlistedApplicantsListingMenu = [
  {
    link: "",
    name: "Shortlisted Applicants Listing",
  },
]

export const CANDIDATE_STATISTICS_MENU = [
  { link: "/candidate/profile/dashboard", name: "Dashboard" },
]

export const COMPANY_STATISTICS_MENU = [
  { link: "/company/profile/dashboard", name: "Dashboard" },
]

export const CANDIDATE_NOTIFICATION_MENU = [
  { link: "/candidate/profile/notifications", name: "Notifications" },
]

export const COMPANY_NOTIFICATION_MENU = [
  { link: "/company/profile/notifications", name: "Notifications" },
]

export const CANDIDATE_CHANGE_PASSWORD_MENU = [
  {
    link: "/candidate/profile/settings/change-password",
    name: "Change Password",
  },
]

export const COMPANY_CHANGE_PASSWORD_MENU = [
  {
    link: "/company/profile/settings/change-password",
    name: "Change Password",
  },
]

export const CART_MENU = [
  {
    link: "/company/profile/cart",
    name: "Cart",
  },
]

export const SHORTLIST_MENU = [
  {
    link: "/company/profile/shortlist",
    name: "Shortlist",
  },
]

export const CHECKOUT_MENU = [
  {
    link: "/company/profile/checkout",
    name: "Checkout",
  },
]
export const PAID_CVS_MENU = [
  {
    link: "/company/profile/paid-cvs",
    name: "Paid CVs",
  },
]
