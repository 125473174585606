import React, { useContext, useEffect, useState } from "react"

import { navigate, Link } from "gatsby"
import { Container, Col, Row } from "react-bootstrap"

import { GlobalAuthContext } from "../../context/authContext"

import SEO from "../seo"
import "./company-profile.scss"
import Header from "../header/header"
import Spinner from "../spinner/spinner"
import ProfileDataFetch from "./profile-data"
import BreadcrumbComponent from "../breadcrumb/breadcrum"
import CopyRight from "../footer/copy-right"
import { COMPANY_COUNTS } from "../shared-actions/countActions"
import { checkNavigable } from "../../utils/checkNavigable"
import { setRedirect } from "../../utils/setRedirect"
import { COMPANY_DETAIL_FETCH } from "../company-profile/actions/detailActions"
import SearchButton from "../search/search-button"
import { GET_CONFIG_DATA } from "../shared-actions/get-config-data"
import { backdropAndUnscroll } from "../../utils/backdropAndUnscroll"
import LandingPageFooter from "../landing-page-footer"

const CompanyProfileLayout = ({
  active,
  children,
  title,
  menu,
  location,
  showSearchButton,
}) => {
  const { state, dispatch } = useContext(GlobalAuthContext)

  // eslint-disable-next-line no-unused-vars
  const [counts, setCounts] = useState({
    notification: 0,
    shortlist: 0,
    cart: 0,
    paidcvs: 0,
  })

  const currentPage = {
    name: "Fill in the details to complete your profile!",
  }
  const home = [{ name: "Home", link: "/" }]

  const [isMenuOpen, setMenuOpen] = useState(false)
  const [wholePageLoading, setWholePageLoading] = useState(true)

  const handleHamClick = value => {
    let val = !isMenuOpen
    if (value === 0) val = false
    backdropAndUnscroll(!val)
    setMenuOpen(val)
  }

  const backdropHandler = value => {
    backdropAndUnscroll(value)
  }
  const fetchData = async () => {
    if (
      state?.isAuthenticated &&
      state?.user?.role === 3 &&
      state?.hasProfile &&
      state?.isEmailVerified
    ) {
      if (!state?.config) {
        await GET_CONFIG_DATA(() => {}, dispatch)
      }
      await COMPANY_COUNTS(setCounts, dispatch)
      setWholePageLoading(false)
    } else if (state?.isAuthenticated && !state?.isEmailVerified) {
      navigate("/verify-email")
    } else if (
      state?.isAuthenticated &&
      state?.user?.role === 3 &&
      state?.isEmailVerified &&
      !state?.hasProfile
    ) {
      checkForValidity()
    } else if (state?.isAuthenticated && state?.user?.role === 2) {
      navigate("/")
    } else if (!localStorage?.token) {
      navigate("/")
    }
  }

  const checkForValidity = async () => {
    const res = await COMPANY_DETAIL_FETCH(
      dispatch,
      () => {},
      {},
      () => {}
    )
    if (res?.data?.profile?.verified) {
      setWholePageLoading(false)
    } else {
      if (
        location?.pathname === "/company/company-detail/" ||
        location?.pathname === "/company/company-detail"
      ) {
        setWholePageLoading(false)
      } else {
        setRedirect(dispatch, location?.pathname + location?.search)
        navigate("/company/company-detail")
      }
    }
  }

  useEffect(() => {
    fetchData()
    backdropHandler(true)
  }, [state.hasProfile, state.isAuthenticated]) //eslint-disable-line react-hooks/exhaustive-deps

  if (wholePageLoading) {
    return <Spinner />
  } else {
    return (
      <>
        {state.isAuthenticated ? (
          <>
            <SEO title={title} />
            <Header
              hide={true}
              siteTitle={title}
              isMenuOpen={isMenuOpen}
              handleHamClick={handleHamClick}
            />

            <BreadcrumbComponent
              style={showSearchButton ? "mb-0" : ""}
              pages={!!state?.hasProfile ? home : []}
              currentPage={
                !!state?.hasProfile ? { name: title, link: "" } : currentPage
              }
            />
            {showSearchButton && <SearchButton />}
            <section className="company-profile mb-5-rem">
              <Container>
                <Row>
                  <Col md="9 m-auto" className=" profile-border">
                    <ProfileDataFetch />
                    <div className="company-profile__wrapper">
                      <Row>
                        <Col>
                          <ul className="step-tab-list d-flex pl-4 pr-4 mb-2 ">
                            {menu &&
                              menu.length > 0 &&
                              menu.map((menuItem, index) => {
                                return (
                                  <li
                                    key={index}
                                    className={
                                      active === menuItem.name ? "active " : ""
                                    }
                                  >
                                    {state.isAuthenticated &&
                                      (state.hasProfile ||
                                      checkNavigable(menuItem.name) ? (
                                        <Link
                                          className="profile_link_allowed mb-2"
                                          to={`${menuItem.link}`}
                                        >
                                          {menuItem.name}
                                        </Link>
                                      ) : (
                                        <span className="profile_link_notallowed mb-2">
                                          {menuItem.name}
                                        </span>
                                      ))}
                                  </li>
                                )
                              })}
                          </ul>
                        </Col>
                      </Row>
                      <div className="form-container">{children}</div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
            {/* <LandingPageFooter /> */}
          </>
        ) : (
          <Spinner />
        )}
      </>
    )
  }
}

export default CompanyProfileLayout
